import { LocationStyles, PriceAppConfig, PricingProcessor, StationType } from "common";
import { LOCAL_SETTINGS_KEY, ORG_STYLES_KEY, PRICE_APP_CONFIG_KEY, USER_SESSIONS_KEY } from "../constants";
import { LocalSettings } from "../pages/SettingsPage/SettingsPage";
import { UserSession } from "../types";

export function getOrgStyles(): LocationStyles {
    const orgStyles = localStorage.getItem(ORG_STYLES_KEY);
    if (orgStyles) {
        return JSON.parse(orgStyles);
    }

    return {
        primaryColor: "#35644F",
        secondaryColor: "#00B764",
        logo: "https://images.thriftly.com/thriftly-logo.svg",
        logoDarkMode: "https://images.thriftly.com/thriftly-logo-white.svg",
        hidePoweredByThriftly: false,
    };
}

export function setOrgStyles(orgStyles: LocationStyles) {
    localStorage.setItem(ORG_STYLES_KEY, JSON.stringify(orgStyles));
}

export function getPriceAppConfig(): PriceAppConfig {
    const orgStyles = localStorage.getItem(PRICE_APP_CONFIG_KEY);
    if (orgStyles) {
        return JSON.parse(orgStyles);
    }

    return {};
}

export function setPriceAppConfig(priceAppConfig: PriceAppConfig) {
    localStorage.setItem(PRICE_APP_CONFIG_KEY, JSON.stringify(priceAppConfig));
}

export function getLocalSettings(): LocalSettings {
    let localSettings: LocalSettings = {};
    const localSettingsValue = localStorage.getItem(LOCAL_SETTINGS_KEY);
    if (localSettingsValue) {
        localSettings = JSON.parse(localSettingsValue);
    }

    return {
        stationType: localSettings.stationType || StationType.StationTypeSoftline,
        aiGenerateProcessor: localSettings.aiGenerateProcessor || PricingProcessor.ProcessorGemini,
        cameraRapidPricing: localSettings.cameraRapidPricing || "true",
        cameraRotation: localSettings.cameraRotation || "0",
        cameraFormat: localSettings.cameraFormat || "square",
        cameraResolution: localSettings.cameraResolution || "1080p",
        aiAssistantImageCount: localSettings.aiAssistantImageCount || "3",
        labelPrinterServer: localSettings.labelPrinterServer || "",
    };
}

export function setLocalSettings(localSettings: LocalSettings) {
    localStorage.setItem(LOCAL_SETTINGS_KEY, JSON.stringify(localSettings));
}

export function getUserSessions(): UserSession[] {
    const userSessions = localStorage.getItem(USER_SESSIONS_KEY);
    if (userSessions) {
        return JSON.parse(userSessions);
    }

    return [];
}

export function setUserSessions(userSessions: UserSession[]) {
    localStorage.setItem(USER_SESSIONS_KEY, JSON.stringify(userSessions));
}
