import { isSet } from "common";
import { MdRotateLeft } from "react-icons/md";

type InputProps = {
    className?: string;
    labelTextColor?: string;
    textColor?: string;
    backgroundColor?: string;
    disabled?: boolean;
    label?: string;
    placeholder?: string;
    onChange: (value: string | undefined) => void;
    onBlur?: (value: string) => void;
    onEnter?: (value: string) => void;
    onUndo?: () => void;
    clickToSelect?: boolean;
    originalValue?: string;
    value?: string;
    generatedValue?: string;
};

export default function GeneratedInputTextArea(props: InputProps) {
    const {
        className,
        labelTextColor,
        textColor,
        backgroundColor,
        disabled,
        label,
        originalValue,
        value,
        generatedValue,
        placeholder,
        onChange,
        onBlur,
        onEnter,
        onUndo,
        clickToSelect,
    } = props;

    const hasGeneratedValue = isSet(generatedValue) && (generatedValue !== value || generatedValue !== originalValue);

    const getLabelTextColor = () => {
        if (disabled) {
            return "text-gray-500";
        }
        if (labelTextColor) {
            return labelTextColor;
        }
        return "text-black";
    };

    const getTextColor = () => {
        if (hasGeneratedValue && value != generatedValue) return "text-white";
        if (hasGeneratedValue) return "text-[#4BFF45]";
        if (disabled) {
            return "text-gray-500";
        }
        if (textColor) {
            return textColor;
        }
        return "text-black";
    };

    const getBackgroundColor = () => {
        if (disabled) {
            return "bg-white";
        }
        if (backgroundColor) {
            return backgroundColor;
        }
        return "bg-white";
    };

    const undo = () => {
        if (onUndo) onUndo();
    };

    return (
        <div
            className={`flex w-full flex-col rounded-lg border border-gray-300 p-2 ${getBackgroundColor()} ${className}`}
        >
            {label && value !== "" && value !== undefined && (
                <label
                    className={`mx-2 flex flex-row justify-between text-xs font-semibold ${getLabelTextColor()} ${getBackgroundColor()}`}
                    htmlFor={label}
                >
                    {label}
                    {value && hasGeneratedValue && (
                        <div className="flex shrink-0 cursor-pointer flex-row text-[#4BFF45]" onClick={undo}>
                            <MdRotateLeft className="h-[16px] w-[16px]" fill="#4BFF45" />
                            UNDO
                        </div>
                    )}
                </label>
            )}
            <textarea
                className={`h-full w-full rounded-md border-none px-2 focus:outline-none focus:ring-0 ${getTextColor()} ${getBackgroundColor()}`}
                name={label}
                onChange={(e) => onChange && onChange(e.target.value)}
                onBlur={(e) => onBlur && onBlur(e.target.value)}
                onKeyDown={(e) => {
                    if (onEnter && e.key === "Enter") {
                        onEnter(value || "");
                    }
                }}
                onClick={(e) => clickToSelect && window.innerWidth < 1000 && e.currentTarget.select()}
                value={value}
                placeholder={placeholder}
            />
        </div>
    );
}
