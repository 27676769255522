import { AxiosError } from "axios";
import { ErrorResponse, LocationStyles, validateEmail } from "common";
import { useContext, useState } from "react";
import CustomInputText from "../components/common/CustomInputText";
import Loader from "../components/common/Loader";
import { StyledButton } from "../components/common/StyledButton";
import { ApiContext } from "../components/context/ApiContext";
import { ROUTES } from "../constants";
import { getOrgStyles } from "../utils/localStorage";

function ForgotPasswordPage() {
    const { login } = useContext(ApiContext);
    const [orgStyles] = useState<LocationStyles>(getOrgStyles());
    const [email, setEmail] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [recoverError, setRecoverError] = useState<string>();
    const [recoverMessage, setRecoverMessage] = useState<string>("");

    const handleRecover = () => {
        if (!validateEmail(email)) return;
        setRecoverError("");
        setRecoverMessage("");
        setIsLoading(true);

        login
            ?.recoverPasswordCreate({ email })
            .then(() => {
                setRecoverMessage("Password recovery email sent. Check your inbox.");
            })
            .catch((err: AxiosError) => {
                setRecoverError((err.response?.data as ErrorResponse)?.error || "An error occurred. Please try again.");
            })
            .finally(() => setIsLoading(false));
    };

    return (
        <div className="flex h-dvh items-center justify-center">
            <div className="flex w-[500px] flex-col items-center gap-1 rounded-lg border border-solid bg-thriftlyOffWhite p-10">
                <img width="200px" src={orgStyles.logo} alt="logo" />
                <h1 className="my-4 text-xl">Forgot your password?</h1>

                <CustomInputText
                    className="w-full"
                    type="email"
                    label="Email address"
                    placeholder="Email address"
                    value={email}
                    onChange={setEmail}
                    onEnter={handleRecover}
                />

                <StyledButton
                    backgroundColor={orgStyles.primaryColor}
                    className="w-full"
                    disabled={!validateEmail(email)}
                    onClick={handleRecover}
                >
                    Send email
                </StyledButton>

                {isLoading && (
                    <div className="mt-4">
                        <Loader />
                    </div>
                )}
                {recoverError && <p className="mt-4 text-red-500">{recoverError}</p>}
                {recoverMessage && <p className="mt-4 text-thriftlyForest">{recoverMessage}</p>}

                <hr className="my-4 h-px w-full bg-thriftlyForest" />

                <a href={ROUTES.LOGIN} className="text-thriftlyForest">
                    Back to log in
                </a>
            </div>
        </div>
    );
}

export default ForgotPasswordPage;
