import { MdCancel } from "react-icons/md";

export default function ImageModal({ image, onHide }: { image?: string; onHide: () => void }) {
    return (
        <>
            <div className="absolute left-0 top-0 h-full w-full bg-thriftlyGreyDark opacity-75" />
            <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center" onClick={onHide}>
                <div className="flex justify-end p-1">
                    <MdCancel className="absolute cursor-pointer rounded-thriftlyIcon bg-thriftlyOffWhite text-4xl text-black" />
                    <div className="m-2 w-full overflow-hidden rounded-thriftlyImage border-2 bg-thriftlyGreyDark">
                        <img
                            className="max-h-screen-4/5 min-h-screen-1/2 object-contain"
                            src={image}
                            alt="item image"
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
