import { TOKEN_KEY, UserToken } from "common";
import { jwtDecode } from "jwt-decode";
import { SESSION_KEY } from "../constants";

export function getInfoFromToken() {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
        const parsedToken = jwtDecode<UserToken>(token);

        return {
            authenticated: parsedToken.exp * 1000 > Date.now(),
            userId: parsedToken.id,
            orgId: parsedToken.orgId || "",
            role: parsedToken.role,
            email: parsedToken.email || "",
            session: localStorage.getItem(SESSION_KEY) || undefined,
            token,
        };
    }

    return {
        authenticated: false,
        userId: "",
        orgId: "",
        role: "",
        email: "",
        issuer: "",
        session: undefined,
        token,
    };
}

export function getInfoFromSessionToken() {
    const token = localStorage.getItem(SESSION_KEY);
    if (token) {
        const parsedToken = jwtDecode<UserToken>(token);

        return {
            authenticated: parsedToken.exp * 1000 > Date.now(),
            userId: parsedToken.id,
            orgId: parsedToken.orgId || "",
            role: parsedToken.role,
            email: parsedToken.email || "",
            session: undefined,
            token,
        };
    }

    return {
        authenticated: false,
        userId: "",
        orgId: "",
        role: "",
        email: "",
        issuer: "",
        session: undefined,
        token,
    };
}
