import { Process } from "common";

export default function ProcessTile(props: { process: Process; onClick?: (process: Process) => void }) {
    const { process, onClick } = props;

    const getSrcWithSize = (src: string) => {
        if (!src) {
            return "";
        }
        return `${src}?width=300&height=300`;
    };

    return (
        <div
            className="flex cursor-pointer flex-row gap-2 text-white"
            onClick={() => (onClick ? onClick(process) : null)}
        >
            <div className="flex shrink-0 items-center overflow-hidden rounded-thriftlyButton bg-thriftlyOffWhite">
                <img
                    className="max-h-[68px] w-[68px] object-contain"
                    src={getSrcWithSize(process.images ? process.images[0] : "")}
                />
            </div>
            <div className="flex w-full flex-col">
                <div className="flex justify-between gap-4">
                    <div className="text-lg">{process.title}</div>
                </div>
                <div className="line-clamp-2 text-sm opacity-65">{process.price}</div>
            </div>
        </div>
    );
}
