import { LocationStyles, TOKEN_KEY } from "common";
import { useContext, useEffect, useState } from "react";
import { OrgContext } from "../components/context/OrgContext";
import { ROUTES, SESSION_KEY, USER_SESSIONS_KEY } from "../constants";
import { getOrgStyles } from "../utils/localStorage";

function LogoutPage() {
    const { setOrgContext } = useContext(OrgContext);
    const [orgStyles] = useState<LocationStyles>(getOrgStyles());

    useEffect(() => {
        localStorage.removeItem(TOKEN_KEY);
        localStorage.removeItem(SESSION_KEY);
        localStorage.removeItem(USER_SESSIONS_KEY);
        setOrgContext();
    }, []);

    return (
        <div className="flex h-dvh items-center justify-center">
            <div className="flex w-[400px] flex-col items-center gap-1 rounded-lg border border-solid bg-thriftlyOffWhite p-10">
                <img width="200px" src={orgStyles.logo} alt="logo" />

                <p className="mt-4 text-thriftlyForest">You have been successfully logged out.</p>

                <hr className="my-4 h-px w-full bg-thriftlyForest" />

                <a href={ROUTES.LOGIN} className="text-thriftlyForest">
                    Back to log in
                </a>
            </div>
        </div>
    );
}

export default LogoutPage;
