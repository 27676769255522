import { Comparable } from "common";
import { MdOpenInNew, MdSearch } from "react-icons/md";

export default function ComparableTileDesktop(props: {
    comparable: Comparable;
    selected?: boolean;
    onClick?: (comparable: Comparable, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    onClickImage?: (comparable: Comparable) => void;
}) {
    const { comparable, selected, onClick, onClickImage } = props;

    return (
        <div
            className={`flex h-[378px] w-[250px] cursor-pointer flex-col gap-2 rounded-thriftlyTile text-thriftlyOffWhite ${selected ? "border-2 border-thriftlyOffWhite bg-thriftlyGreyDark shadow-thriftlyTile" : "border border-thriftlyGrey"}`}
            onClick={(e) => (onClick ? onClick(comparable, e) : null)}
        >
            <div className="relative m-2 flex items-end justify-end overflow-hidden rounded-thriftlyCamera bg-thriftlyGreyDark">
                <div
                    className="absolute m-2 flex h-[48px] w-[48px] cursor-pointer items-center justify-center overflow-hidden rounded rounded-full border-2 border-white bg-thriftlyGreyDark"
                    onClick={(e) => {
                        e.stopPropagation();
                        if (onClickImage) onClickImage(comparable);
                    }}
                >
                    <MdSearch fill="#ffffff" className="h-[32px] w-[32px]" />
                </div>
                <img className="h-[240px] w-[240px] object-contain" src={comparable.image} alt="comparable" />
            </div>
            <div className="flex flex-col px-2">
                <div className="line-clamp-1 text-xl">{comparable.title}</div>
                <div className="line-clamp-1 text-xl text-thriftlyGreyLight">
                    {comparable.price ? comparable?.currency + comparable.price.toFixed(2) : ""}
                </div>
            </div>
            <div
                className="mt-auto flex flex-row items-center justify-between gap-4 overflow-hidden rounded-b-thriftlyTile bg-thriftlyGreyDark px-4 py-2"
                onClick={(e) => {
                    e.stopPropagation();
                    window.open(comparable.link, "_blank");
                }}
            >
                <div className="line-clamp-1 text-xl lowercase">{comparable.source}</div>
                <div className="flex h-[25px] w-[25px] shrink-0 cursor-pointer flex-row items-center justify-center">
                    <div className="flex h-[24px] w-[24px] items-center justify-center rounded bg-opacity-10">
                        <MdOpenInNew fill="#ffffff" className="h-[24px] w-[24px]" />
                    </div>
                </div>
            </div>
        </div>
    );
}
