import { useState } from "react";
import { MdClose, MdWarning } from "react-icons/md";

export default function WarningToast(props: { text: string; closeable?: boolean }) {
    const { text, closeable } = props;
    const [isHidden, setIsHidden] = useState(false);

    if (isHidden) return null;

    return (
        <div
            className={`text-thriftlyBlack mt-auto flex shrink flex-row items-center justify-between gap-4 rounded-thriftlyButton bg-thriftlyOffWhite p-4 text-xl ${closeable ? "cursor-pointer" : ""}`}
            onClick={() => {
                if (closeable) setIsHidden(true);
            }}
        >
            <div className="flex flex-row items-center gap-4">
                <MdWarning fill="#000000" className="h-[24px] w-[24px]" />
                {text}
            </div>
            {closeable && <MdClose />}
        </div>
    );
}
