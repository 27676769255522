import { AxiosError } from "axios";
import { ErrorResponse, isSet, Process, ProcessResponse } from "common";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../components/common/Loader";
import { ApiContext } from "../../components/context/ApiContext";
import { ROUTES } from "../../constants";
import { getPriceAppConfig } from "../../utils/localStorage";
import ProcessPage from "./ProcessPage";

export default function ProcessPageLoader() {
    const navigate = useNavigate();
    const params = useParams();
    const { processes: processApi } = useContext(ApiContext);

    const [isLoading, setIsLoading] = useState(true);
    const [process, setProcess] = useState<Process>();

    // fetch process using path param
    useEffect(() => {
        processApi
            ?.processesDetail(params.id!)
            .then(({ data }: { data: ProcessResponse }) => {
                if (!data.process) {
                    toast("An error occurred. Please try again.");
                    navigate(ROUTES.HOME);
                    return;
                }
                let processDraft = data.process;
                if (getPriceAppConfig().descriptionTemplate && !isSet(processDraft.notes)) {
                    processDraft = {
                        ...processDraft,
                        notes: getPriceAppConfig().descriptionTemplate,
                    };
                }
                setProcess(processDraft);
            })
            .catch((err: AxiosError) => {
                console.error((err.response?.data as ErrorResponse)?.error);
                toast("An error occurred. Please try again.");
                navigate(ROUTES.HOME);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    if (isLoading) {
        return (
            <div className="flex h-screen items-center justify-center">
                <Loader />
            </div>
        );
    }

    return <ProcessPage process={process} />;
}
