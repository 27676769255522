import { CartIcon, ShareIcon, ThumbsDownIcon, ThumbsUpIcon } from "@shopify/polaris-icons";
import { AxiosError } from "axios";
import { Comparable, ErrorResponse, PricingProcessor, Process, ProcessResponse } from "common";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button } from "../../components/common/Button";
import CustomInputTextArea from "../../components/common/CustomInputTextArea";
import Loader from "../../components/common/Loader";
import ToggleIcon from "../../components/common/ToggleIcon";
import { ApiContext } from "../../components/context/ApiContext";
import Header from "../../components/header/Header";
import ImageModal from "../../components/ImageModal";
import Modal from "../../components/Modal";
import ComparableTileMobile from "../ProcessPage/Components/ComparableTileMobile";

export default function ShopPage() {
    const params = useParams();
    const { processes: processApi } = useContext(ApiContext);

    const [isLoading, setIsLoading] = useState(true);
    const [process, setProcess] = useState<Process>(Object);
    const [selectedImage, setSelectedImage] = useState<string>();
    const [showImageModal, setShowImageModal] = useState(false);
    const [feedback, setFeedback] = useState<"up" | "down" | undefined>();
    const [feedbackComment, setFeedbackComment] = useState("");
    const [showFeedbackInput, setShowFeedbackInput] = useState(false);

    // get process id from route params
    useEffect(() => {
        processApi
            ?.processesDetail(params.id!)
            .then(({ data }: { data: ProcessResponse }) => {
                if (data.process) {
                    setProcess(data.process);

                    if (
                        (data.process.processor == PricingProcessor.ProcessorNone ||
                            data.process.comparables?.length == 0) &&
                        data.process.inventory != undefined &&
                        data.process.inventory.cartLink != undefined
                    ) {
                        window.location.href = data.process.inventory.cartLink;
                    } else {
                        setIsLoading(false);
                    }
                }
            })
            .catch((err: AxiosError) => {
                console.error((err.response?.data as ErrorResponse)?.error);
                toast("An error occurred. Please try again.");
            });
    }, []);

    const selectImage = (image: string | undefined) => {
        setSelectedImage(image);
        setShowImageModal(true);
    };

    const onFeedback = (type: "up" | "down") => () => {
        setFeedback(type);
        setShowFeedbackInput(true);
    };

    const sendFeedback = () => {
        setShowFeedbackInput(false);
        setFeedbackComment("");
        toast("Thanks! Your feedback helps improve listings.");

        processApi
            ?.feedbackCreate(process.id!, {
                feedback,
                comment: feedbackComment,
            })
            .then()
            .catch((err: AxiosError) => {
                console.error((err.response?.data as ErrorResponse)?.error);
            });
    };

    const getSrcWithSize = (src: string) => {
        if (!src) {
            return "";
        }
        return `${src}?width=300&height=300`;
    };

    if (isLoading) {
        return (
            <div className="flex h-dvh w-full items-center justify-center">
                <Loader />
            </div>
        );
    }

    return (
        <div className="flex h-dvh flex-col justify-between overflow-y-clip overscroll-none">
            <Header darkMode right={<div className="pr-2 text-right text-white">Get 10% off</div>} />
            <div className="border-t border-thriftlyGreyDark px-4 py-2 text-white">Comparables</div>
            <div className="flex shrink flex-col gap-4 overflow-y-scroll px-4 pb-4">
                {process?.comparables?.map((comparable: Comparable, idx: number) => {
                    return (
                        <div key={idx}>
                            <ComparableTileMobile
                                comparable={comparable}
                                onClickImage={(c: Comparable) => {
                                    selectImage(c.image);
                                }}
                            />
                        </div>
                    );
                })}
            </div>
            <Modal>
                <div className="flex w-full flex-col gap-4">
                    <div className="flex w-full flex-row items-center gap-2 p-2">
                        <div className="flex shrink-0 items-center overflow-hidden rounded-thriftlyImage">
                            <img
                                className="w-[80px] object-contain"
                                src={getSrcWithSize(process?.images?.[0] || "")}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    selectImage(process?.images?.[0] || "");
                                }}
                            />
                        </div>
                        <div className="flex w-full flex-col">
                            <div className="flex flex-row justify-between">
                                <div className="text-xl text-thriftlyBlackText">
                                    {process?.price ? process.price : null}
                                </div>
                                <div className="flex flex-row gap-2">
                                    <ToggleIcon
                                        defaultColor="black"
                                        height={32}
                                        icon={ThumbsUpIcon}
                                        onClick={onFeedback("up")}
                                        toggled={feedback === "up"}
                                        toggledColor="white"
                                    />
                                    <ToggleIcon
                                        defaultColor="black"
                                        height={32}
                                        icon={ThumbsDownIcon}
                                        onClick={onFeedback("down")}
                                        toggled={feedback === "down"}
                                        toggledColor="white"
                                    />
                                </div>
                            </div>
                            <div className="text-sm capitalize text-black opacity-90">{process?.condition}</div>
                            <div className="line-clamp-2 text-sm opacity-65">{process?.title}</div>
                            <div className="line-clamp-1 text-sm opacity-65">{process?.notes}</div>
                        </div>
                    </div>
                    {showFeedbackInput && (
                        <div className="flex flex-col gap-2">
                            <CustomInputTextArea
                                label="Comment"
                                placeholder="Comment"
                                value={feedbackComment}
                                onChange={setFeedbackComment}
                                clickToSelect
                            />
                        </div>
                    )}
                    <div className="flex flex-col gap-4">
                        {!showFeedbackInput ? (
                            <>
                                <Button
                                    className="w-full border-black"
                                    onClick={async () => {
                                        const data = {
                                            url: window.location.href,
                                            text: "",
                                        };

                                        if (navigator.share !== undefined) {
                                            try {
                                                await navigator.share(data);
                                            } catch {
                                                // suppress exception
                                            }
                                        } else {
                                            toast("Sharing is not supported on this device.");
                                        }
                                    }}
                                >
                                    <ShareIcon height={40} fill="#000000" /> Share
                                </Button>
                                <Button
                                    backgroundColor="bg-thriftlyBlackBackground"
                                    textColor="text-white"
                                    className="w-full border-black"
                                    onClick={() => {
                                        window.open(process?.inventory?.cartLink, "_blank");
                                    }}
                                >
                                    <CartIcon height={32} fill="#FFFFFF" /> Add to cart
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button
                                    className="w-full"
                                    onClick={() => {
                                        setFeedback(undefined);
                                        setShowFeedbackInput(false);
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    backgroundColor="bg-thriftlyBlackBackground"
                                    textColor="text-white"
                                    className="w-full border-black"
                                    onClick={sendFeedback}
                                >
                                    Submit
                                </Button>
                            </>
                        )}
                    </div>
                </div>
            </Modal>
            {showImageModal && <ImageModal image={selectedImage} onHide={() => setShowImageModal(false)} />}
        </div>
    );
}
