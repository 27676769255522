import { AxiosError } from "axios";
import { ErrorResponse, Organization, OrganizationResponse, Organizations, Process, ProcessesResponse } from "common";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

function formatDateOnly(d: Date): string {
    return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, "0")}-${String(d.getDate()).padStart(2, "0")}`;
}

export default function PullListPage() {
    const { VITE_API_BASE_URL } = import.meta.env;
    const [searchParams] = useSearchParams();
    const [organization, setOrganization] = useState<Organization>();
    const [processes, setProcesses] = useState<Process[]>();
    const [error, setError] = useState<string>("");

    const token = searchParams.get("token");
    const org = searchParams.get("org_id");
    const date = searchParams.get("date") ? new Date(searchParams.get("date")!) : new Date();

    useEffect(() => {
        if (!org || token === null) {
            console.error("Organization ID and token is required.");
            setError("Organization ID and token is required.");
            return;
        }

        const organizationsApi = new Organizations({
            baseURL: `${VITE_API_BASE_URL}`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        organizationsApi
            ?.organizationsDetail(org)
            .then(({ data }: { data: OrganizationResponse }) => {
                setOrganization(data.organization);
            })
            .catch((err: AxiosError) => {
                console.error((err.response?.data as ErrorResponse)?.error || "An error occurred. Please try again.");
                setError((err.response?.data as ErrorResponse)?.error || "An error occurred. Please try again.");
            });

        organizationsApi
            ?.pullListDetail(org, { date: formatDateOnly(date) })
            .then(({ data }: { data: ProcessesResponse }) => {
                setProcesses(data.processes);
            })
            .catch((err: AxiosError) => {
                console.error((err.response?.data as ErrorResponse)?.error || "An error occurred. Please try again.");
                setError((err.response?.data as ErrorResponse)?.error || "An error occurred. Please try again.");
            });
    }, []);

    const emptyCells = () => {
        const cells = processes?.length ? 4 - (processes.length % 4) : 4;
        return new Array(cells)
            .fill(null)
            .map((_, idx: number) => <tr className="h-[185px] break-inside-avoid break-after-auto" key={idx} />);
    };

    if (error) {
        return (
            <div className="h-screen bg-thriftlyOffWhite">
                <div className="flex h-full flex-col items-center justify-center">
                    <div className="text-3xl text-slate-900">Error generating pull list: {error}</div>
                </div>
            </div>
        );
    }

    return (
        <div className="h-full bg-thriftlyOffWhite">
            <table className="w-full border-collapse break-inside-auto">
                <thead>
                    <tr>
                        <td colSpan={5}>
                            <div className="flex w-full flex-row items-center justify-between align-middle">
                                <div className="text-3xl text-slate-900">
                                    {date.toLocaleDateString().replace(/\//g, "-")} Pull List
                                </div>
                                <img className="h-[48px]" src={organization?.styles?.logo} alt="logo" />
                            </div>
                            <hr className="mb-10 mt-4 border border-slate-500" />
                        </td>
                    </tr>
                </thead>
                <thead>
                    <tr>
                        <td className="border border-slate-500 text-center">#</td>
                        <td className="border border-slate-500 pl-2 text-left">Location</td>
                        <td className="border border-slate-500 pl-2 text-left">Picture</td>
                        <td className="border border-slate-500 pl-2 text-left">Title</td>
                        <td className="border border-slate-500 pl-2 text-left">Feedback</td>
                    </tr>
                </thead>
                <tbody>
                    {processes?.map((process: Process, idx: number) => (
                        <tr className="h-[185px] break-inside-avoid break-after-auto" key={process.id}>
                            <td className="border border-slate-500 p-1 text-center">{idx + 1}</td>
                            <td className="border border-slate-500 pl-2">{process.locationId}</td>
                            <td className="border border-slate-500 p-2">
                                {process.images ? <img src={process.images[0]} alt="item image" /> : null}
                            </td>
                            <td className="border border-slate-500 pl-2">{process.title}</td>
                            <td className="min-w-[100px] border border-slate-500 pl-2">{process.feedback}</td>
                        </tr>
                    ))}
                    {emptyCells()}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan={5}>
                            <hr className="mb-4 mt-10 border border-slate-500" />
                            <div className="flex w-full flex-row justify-between align-middle">
                                <div className="flex flex-row gap-2 text-xl text-slate-900">
                                    <div className="align-top font-medium">powered by</div>
                                    <img
                                        src="https://images.thriftly.com/thriftly-logo.png"
                                        className="h-[30px]"
                                        alt="logo"
                                    />
                                </div>
                                <div>Total items: {processes?.length || "0"}</div>
                            </div>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    );
}
