import { UserRole } from "common";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { OrgContext } from "../../components/context/OrgContext";
import { ROUTES } from "../../constants";

export default function HomePage() {
    const { role } = useContext(OrgContext);
    const navigate = useNavigate();

    useEffect(() => {
        switch (role) {
            case UserRole.RoleAdmin:
                navigate(ROUTES.PRODUCTION_PAGE);
                break;
            case UserRole.RolePricer:
                navigate(ROUTES.PRICER_PAGE);
                break;
            case UserRole.RoleLister:
                navigate(ROUTES.LISTER_PAGE);
                break;
            default:
                navigate(ROUTES.LOGOUT);
        }
    }, []);

    return <></>;
}
