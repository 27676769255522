import { ReactNode } from "react";
import { MdCheckCircle } from "react-icons/md";

export default function ScanTile(props: {
    state: "disabled" | "active" | "completed";
    title: string;
    subtitle?: string;
    image?: string;
    children: ReactNode;
    collapse?: boolean;
}) {
    const { state, title, subtitle, image, children, collapse } = props;

    return (
        <div
            className={`flex w-full flex-col gap-4 rounded-thriftlyButton border-2 p-4 text-white ${state === "active" ? "border-thriftlyGreyLight" : "border-thriftlyGreyDark"} ${state === "disabled" ? "pointer-events-none opacity-50" : ""}`}
        >
            <div className="flex flex-row justify-between">
                <div className="flex w-2/3 flex-col">
                    <div className="text-xl text-thriftlyOffWhite">{title}</div>
                    <div className="text-thriftlyGreyLight">{subtitle}</div>
                </div>
                <div className="flex items-center">
                    {state === "disabled" && (
                        <div className="h-[32px] w-[32px] rounded-full border-2 border-thriftlyGreyDark" />
                    )}
                    {state === "active" && (
                        <div className="h-[32px] w-[32px] rounded-full border-2 border-[#00B764] bg-thriftlyGreyDark" />
                    )}
                    {state === "completed" && (
                        <div className="h-[32px] w-[32px] items-center justify-center rounded-full border-2 border-[#00B764] bg-white">
                            <MdCheckCircle className="h-[32px] w-[32px] scale-[130%] fill-[#00B764]" />
                        </div>
                    )}
                </div>
            </div>
            {(state === "active" || !collapse) && (
                <>
                    {image && (
                        <div className="flex h-[256px] shrink-0 justify-center rounded-lg bg-thriftlyGreyDark">
                            <img src={image} alt="barcode scanner" className="object-fill" />
                        </div>
                    )}
                    {children}
                </>
            )}
        </div>
    );
}
