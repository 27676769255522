import { AxiosError } from "axios";
import {
    Condition,
    ErrorResponse,
    isSet,
    NewZebraBrowserPrint,
    PricingProcessor,
    Process,
    ProcessResponse,
    StationType,
    ZebraPrinter,
} from "common";
import { useContext, useEffect, useRef, useState } from "react";
import { MdRefresh } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { SendJsonMessage } from "react-use-websocket/dist/lib/types";
import BarcodeListener from "../../components/BarcodeListener";
import { Button } from "../../components/common/Button";
import Camera, { CameraRef } from "../../components/common/Camera";
import { ApiContext } from "../../components/context/ApiContext";
import { ROUTES } from "../../constants";
import { ScannerResponse } from "../../types";
import { base64toBlob, convertUserImage } from "../../utils/camera";
import { getLocalSettings } from "../../utils/localStorage";
import { canPrint } from "../../utils/printers";
import GeneratedInputText from "../ProcessPage/Components/GeneratedInputField";
import ScanTile from "../ProcessPage/Components/ScanTile";

export default function OneClickPricing({
    lastMessage,
    sendJsonMessage,
    getItemsCount,
}: {
    lastMessage: MessageEvent<string> | null;
    sendJsonMessage: SendJsonMessage;
    getItemsCount: () => void;
}) {
    const { VITE_API_BASE_URL } = import.meta.env;
    const navigate = useNavigate();
    const { processes: processApi } = useContext(ApiContext);
    const process = useRef<Process>();

    const printerLoaded = useRef(false);
    const selectedPrinter = useRef<ZebraPrinter>();
    const [lastPriceLabelPrinted, setLastPriceLabelPrinted] = useState<string>();

    const cameraRef = useRef<CameraRef>(null);
    const [size, setSize] = useState<string>();
    const sizeRef = useRef<string>();
    const skipSize = useRef<boolean>(false);
    const [tagImage, setTagImage] = useState<string>();
    const setCustomPrice = useRef<boolean>(false);

    const scanningSizeTag = () => {
        return tagImage === undefined && !skipSize.current;
    };

    useEffect(() => {
        getItemsCount();

        // get default label printer
        if (canPrint() && !printerLoaded.current) {
            printerLoaded.current = true;

            NewZebraBrowserPrint(getLocalSettings().labelPrinterServer)
                .getDefaultPrinter()
                .then((printer) => (selectedPrinter.current = printer))
                .catch((error) => toast(`Error getting default printer: ${error}`));
        }
    }, []);

    useEffect(() => {
        // reset barcode scanner mode every 31 seconds (30 second timeout) if not processing an item
        const intervalId = setInterval(() => {
            if (scanningSizeTag())
                sendJsonMessage(
                    JSON.stringify({
                        scannerId: "1",
                        commandType: "image_mode",
                    })
                );
        }, 31000);

        return () => clearInterval(intervalId);
    }, [tagImage]);

    // Set barcode scanner to image mode
    useEffect(() => {
        if (scanningSizeTag())
            sendJsonMessage(
                JSON.stringify({
                    scannerId: "1",
                    commandType: "image_mode",
                })
            );
    }, [process.current]);

    // Process image data from barcode scanner
    useEffect(() => {
        if (lastMessage !== null) {
            if (!scanningSizeTag()) return;
            try {
                const msg: ScannerResponse = JSON.parse(lastMessage.data);
                switch (msg.messageType) {
                    case "image":
                        setTagImage(`data:image/jpeg;base64, ${msg.image}`);

                        // upload image
                        processApi
                            ?.processesCreate(
                                {
                                    image: new File([base64toBlob(msg.image!, "image/jpg")], "image", {
                                        type: "image/jpg",
                                    }),
                                },
                                {
                                    processor: getProcessor(),
                                    stationType: getLocalSettings().stationType,
                                    skipInventory: true,
                                }
                            )
                            .then(({ data }: { data: ProcessResponse }) => {
                                process.current = data.process;
                            })
                            .catch((err: AxiosError) => {
                                toast(
                                    (err.response?.data as ErrorResponse)?.error ||
                                        "An error occurred. Please try again."
                                );
                            });
                }
            } catch (e) {
                if (e instanceof Error) {
                    toast(`Error parsing scanner response JSON: ${e.message}`);
                }
                toast("Unknown error parsing scanner response JSON");
            }
        }
    }, [lastMessage]);

    useEffect(() => {
        sizeRef.current = size;
    }, [size]);

    const getProcessor = (price?: string) => {
        if (isSet(price)) return PricingProcessor.ProcessorNone;

        // comparables for hard line, user configured for soft line
        if (getLocalSettings().stationType === StationType.StationTypeHardline) {
            return PricingProcessor.ProcessorSerpApi;
        } else if (getLocalSettings().stationType === StationType.StationTypeSoftline) {
            return getLocalSettings().aiGenerateProcessor;
        }

        return PricingProcessor.ProcessorNone;
    };

    const getSizeTagBody = () => {
        if (skipSize.current) {
            return (
                <div className="flex flex-col gap-4">
                    {size ? <div>Size: {size}</div> : <span className="text-thriftlyGreyLight">Size Skipped</span>}
                    <a
                        className="cursor-pointer underline"
                        onClick={() => {
                            skipSize.current = false;
                            setSize(undefined);
                            sendJsonMessage(
                                JSON.stringify({
                                    scannerId: "1",
                                    commandType: "image_mode",
                                })
                            );
                        }}
                    >
                        Redo
                    </a>
                </div>
            );
        }

        if (tagImage !== undefined) {
            return (
                <div className="flex flex-col gap-4">
                    <img className="w-full rounded-lg border-2 border-thriftlyGreyDark" src={tagImage} />
                    <a
                        className="cursor-pointer underline"
                        onClick={() => {
                            // TODO: Delete incomplete process?
                            process.current = undefined;
                            setTagImage(undefined);
                            sendJsonMessage(
                                JSON.stringify({
                                    scannerId: "1",
                                    commandType: "image_mode",
                                })
                            );
                        }}
                    >
                        Redo
                    </a>
                </div>
            );
        }

        return (
            <div className="flex flex-col gap-4 text-thriftlyGreyLight">
                <div>or input the size:</div>
                <GeneratedInputText
                    labelTextColor="text-thriftlyGreyLight"
                    textColor="text-thriftlyOffWhite"
                    backgroundColor="bg-thriftlyBlackBackground"
                    className={`border-2`}
                    label="Size"
                    placeholder="Size"
                    type="text"
                    value={size || ""}
                    onChange={setSize}
                    onEnter={() => {
                        skipSize.current = true;
                        sendJsonMessage(
                            JSON.stringify({
                                scannerId: "1",
                                commandType: "barcode_mode",
                            })
                        );
                    }}
                />
                <div>
                    or{" "}
                    <a
                        className="cursor-pointer underline"
                        onClick={() => {
                            skipSize.current = true;
                            sendJsonMessage(
                                JSON.stringify({
                                    scannerId: "1",
                                    commandType: "barcode_mode",
                                })
                            );
                        }}
                    >
                        skip this step.
                    </a>
                </div>
            </div>
        );
    };

    const getCategoryAndGradeBody = () => {
        return (
            <div className="flex flex-col items-center">
                <Camera
                    width="w-2/3"
                    ref={cameraRef}
                    onImageCapture={async (
                        userImage: string,
                        setCameraIsLoading: (isLoading: boolean) => void,
                        closeCamera: () => void,
                        clearImage: () => void,
                        price?: string,
                        condition?: string,
                        category?: string,
                        gender?: string
                    ) => {
                        // TODO: Prevent race condition/unwanted outcomes from scanning tag not finished before scanning qr code
                        if (!skipSize.current && !process.current) {
                            alert("Please wait for the size tag to finish processing before scanning the QR code.");
                            clearImage();
                            return;
                        }

                        setCameraIsLoading(true);
                        if (process.current) {
                            // update process
                            await processApi
                                ?.imageCreate(
                                    process.current!.id!,
                                    {
                                        image: convertUserImage(userImage),
                                    },
                                    { primary: true }
                                )
                                .then(async () => {
                                    await processApi
                                        ?.processesUpdate(
                                            process.current!.id!,
                                            {
                                                price,
                                                condition: condition ? (condition as Condition) : undefined,
                                                category,
                                                gender,
                                            },
                                            { generate: true }
                                        )
                                        .then(async (resp) => {
                                            if (setCustomPrice.current) {
                                                closeCamera();
                                                navigate(`${ROUTES.PROCESS_PAGE}/${resp?.data?.process?.id}`);
                                                return;
                                            }
                                            getItemsCount();
                                            toast.success("Item successfully priced");
                                            setLastPriceLabelPrinted(resp.data.process?.id);
                                            process.current = undefined;
                                            setTagImage(undefined);

                                            if (!canPrint() || selectedPrinter.current == undefined) return;
                                            const url = `${VITE_API_BASE_URL}/processes/${resp.data.process?.id}/label?print=true`;
                                            selectedPrinter.current.printZPL(url).catch((error) => {
                                                toast(`Error printing label: ${error}`);
                                                console.error(`Error printing label: ${error}`);
                                            });
                                        })
                                        .catch((err: AxiosError) => {
                                            toast(
                                                (err.response?.data as ErrorResponse)?.error ||
                                                    "An error occurred. Please try again."
                                            );
                                        })
                                        .finally(() => {
                                            setCameraIsLoading(false);
                                            clearImage();
                                        });
                                })
                                .catch((err: AxiosError) => {
                                    toast(
                                        (err.response?.data as ErrorResponse)?.error ||
                                            "An error occurred. Please try again."
                                    );
                                })
                                .finally(() => {
                                    setCameraIsLoading(false);
                                });
                        } else {
                            // create process
                            await processApi
                                ?.processesCreate(
                                    {
                                        image: convertUserImage(userImage),
                                    },
                                    {
                                        price,
                                        condition,
                                        category,
                                        gender,
                                        processor: getProcessor(price),
                                        stationType: getLocalSettings().stationType,
                                        size: sizeRef.current,
                                    }
                                )
                                .then(async (resp) => {
                                    getItemsCount();
                                    toast.success("Item successfully priced");
                                    setLastPriceLabelPrinted(resp.data.process?.id);
                                    process.current = undefined;
                                    skipSize.current = false;
                                    setSize(undefined);

                                    if (!canPrint() || selectedPrinter.current == undefined) return;
                                    const url = `${VITE_API_BASE_URL}/processes/${resp.data.process?.id}/label?print=true`;
                                    selectedPrinter.current.printZPL(url).catch((error) => {
                                        toast(`Error printing label: ${error}`);
                                        console.error(`Error printing label: ${error}`);
                                    });
                                })
                                .catch((err: AxiosError) => {
                                    toast(
                                        (err.response?.data as ErrorResponse)?.error ||
                                            "An error occurred. Please try again."
                                    );
                                })
                                .finally(() => {
                                    setCameraIsLoading(false);
                                    clearImage();
                                });
                        }
                    }}
                >
                    <div>
                        The above is a preview of the image that will be captured.
                        <br />
                        Instead of scanning a QR code, you can{" "}
                        <a
                            className="cursor-pointer underline"
                            onClick={() => {
                                setCustomPrice.current = true;
                                cameraRef?.current?.capture();
                            }}
                        >
                            take a photo and set a custom price
                        </a>
                    </div>
                </Camera>
            </div>
        );
    };

    return (
        <>
            <div className="flex h-full w-full flex-row gap-4 p-8">
                <div className="flex h-full w-1/2">
                    <ScanTile
                        // active until we either scan a tag image or skip size
                        state={!tagImage && !skipSize.current ? "active" : "completed"}
                        title="1. Scan the size tag"
                        // if there is a tag image we are showing the preview instead of the image
                        image={tagImage ? undefined : "/barcode_scanner_tag.svg"}
                    >
                        {getSizeTagBody()}
                    </ScanTile>
                </div>
                <div className="flex h-full w-1/2">
                    <ScanTile
                        // disabled until we either scan a tag imae or skip size
                        state={tagImage || skipSize.current ? "active" : "disabled"}
                        title="2. Scan the category and grade QR code"
                        image="/barcode_scanner_qr_code.svg"
                    >
                        {getCategoryAndGradeBody()}
                    </ScanTile>
                </div>
            </div>
            {lastPriceLabelPrinted && (
                <div className="absolute bottom-0 right-0 mt-auto flex flex-row p-4">
                    <Button
                        className="ml-auto mt-auto"
                        onClick={() => {
                            if (!canPrint() || selectedPrinter.current == undefined) return;
                            const url = `${VITE_API_BASE_URL}/processes/${lastPriceLabelPrinted}/label?print=true`;
                            selectedPrinter.current.printZPL(url).catch((error) => {
                                toast(`Error printing label: ${error}`);
                                console.error(`Error printing label: ${error}`);
                            });
                        }}
                    >
                        <MdRefresh />
                        Reprint label
                    </Button>
                </div>
            )}
            <BarcodeListener
                barcodeData={lastMessage?.data}
                onFixedPriceScan={(scannerId, price, condition, category, gender) => {
                    if (scanningSizeTag()) return; // only handle barcodes after taking tag picture
                    cameraRef?.current?.capture(price, condition, category, gender);
                }}
                onBarcodeScannerImageMode={() => {
                    if (scanningSizeTag()) {
                        sendJsonMessage(
                            JSON.stringify({
                                scannerId: "1",
                                commandType: "image_mode",
                            })
                        );
                    }
                }}
            />
        </>
    );
}
