import { LocationStyles, UserRole } from "common";
import { useState } from "react";
import { getOrgStyles } from "../../utils/localStorage";

export default function Itembadge({ role, itemsCount }: { role: UserRole; itemsCount?: number }) {
    const [orgStyles] = useState<LocationStyles>(getOrgStyles());

    const suffix = () => {
        if (itemsCount === 1) {
            return role === UserRole.RolePricer ? "item today" : "item left";
        }
        return role === UserRole.RolePricer ? "items today" : "items left";
    };

    return (
        <div className="flex h-[20px] shrink-0 items-center justify-end">
            <div
                className="rounded px-2 py-1 text-xs uppercase"
                style={{
                    backgroundColor: orgStyles.primaryColor,
                    color: orgStyles.secondaryColor,
                }}
            >
                {itemsCount !== undefined ? itemsCount : "-"} {suffix()}
            </div>
        </div>
    );
}
