import { isMobile } from "react-device-detect";
import { getPriceAppConfig } from "./localStorage";

export function canPrint(): boolean {
    return (
        (isMobile && getPriceAppConfig().printPriceLabelsMobile) ||
        (!isMobile && getPriceAppConfig().printPriceLabelsDesktop) ||
        false
    );
}
