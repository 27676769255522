import { createTheme, ThemeProvider } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { MagicIcon } from "@shopify/polaris-icons";
import { isMobile } from "react-device-detect";
import { MdCheck, MdRepeat } from "react-icons/md";
import ReactLoading from "react-loading";

export default function AiAssistantWidget(props: {
    progress: number;
    onGenerate: () => void;
    isWorking: boolean;
    timeSaved: number;
}) {
    const { progress, onGenerate, isWorking, timeSaved } = props;

    const getTextSizeClass = () => {
        if (isMobile) {
            return "text-sm";
        }
        return "text-xl";
    };

    const getIconSize = () => {
        if (isMobile) {
            return 24;
        }
        return 30;
    };

    const getIconSizeClass = () => {
        if (isMobile) {
            return "w-[24px] h-[24px]";
        }
        return "w-[30px] h-[30px]";
    };

    const getStatus = () => {
        if (timeSaved > 0) {
            return (
                <div className="flex flex-row items-center gap-2 text-white">
                    <MdCheck fill="#4BFF45" className={getIconSizeClass()} />
                    Time saved: {timeSaved.toLocaleString()}s
                </div>
            );
        }
        if (isWorking) {
            return (
                <div className="flex flex-row items-center gap-2 text-white">
                    <ReactLoading type="spin" color="#4BFF45" height={getIconSize()} width={getIconSize()} />
                    Working
                </div>
            );
        }

        return (
            <div className="flex flex-row items-center gap-2 text-white">
                <MdRepeat className={getIconSizeClass()} />
                Generate
            </div>
        );
    };

    return (
        <div
            className={`${getTextSizeClass()} flex flex-col overflow-hidden rounded-thriftlyButton bg-thriftlyGreyDark shadow-thriftlyAiAssistant ${!isWorking && timeSaved === 0 ? "cursor-pointer" : ""}`}
        >
            <ThemeProvider
                theme={createTheme({
                    palette: { mode: "dark", primary: { main: "#4BFF45" } },
                })}
            >
                <LinearProgress variant="determinate" value={timeSaved > 0 ? 100 : progress} />
            </ThemeProvider>
            <div
                className={`flex flex-row justify-between ${isMobile ? "px-4 py-2" : "px-6 py-4"}`}
                onClick={() => (!isWorking && timeSaved === 0 ? onGenerate() : null)}
            >
                <div className="flex flex-row items-center gap-2 text-nowrap text-[#4BFF45]">
                    <MagicIcon className={getIconSizeClass()} fill="#4BFF45" />
                    AI Assistant
                </div>
                {getStatus()}
            </div>
        </div>
    );
}
