import { Comparable } from "common";
import { useRef } from "react";
import ComparableTileMobile from "./ComparableTileMobile";

export default function ComparableSelectorMobile(props: {
    comparables: Comparable[];
    onTakePicture: () => void;
    onSelectComparable: (comparable: Comparable) => void;
    onSelectImage: (image: string) => void;
}) {
    const { comparables, onTakePicture, onSelectComparable, onSelectImage } = props;
    const scrollRef = useRef<HTMLDivElement>(null);

    return (
        <div className="flex h-full flex-col overflow-y-auto overscroll-y-auto">
            <div className="flex flex-row gap-2 text-nowrap py-2 pl-4 text-white">
                Select a comp or{" "}
                <span className="cursor-pointer underline" onClick={() => onTakePicture()}>
                    take a picture of the label
                </span>
            </div>
            <div className="flex shrink flex-col gap-4 px-4 pb-4" ref={scrollRef}>
                {comparables?.map((comparable: Comparable, idx: number) => {
                    return (
                        <div key={idx}>
                            <ComparableTileMobile
                                comparable={comparable}
                                onClick={(c: Comparable) => {
                                    onSelectComparable(c);
                                    if (scrollRef.current) {
                                        scrollRef.current.scroll({
                                            top: 0,
                                            behavior: "smooth",
                                        });
                                    }
                                }}
                                onClickImage={(c: Comparable) => {
                                    onSelectImage(c.image!);
                                }}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
