import { AxiosError } from "axios";
import { ErrorResponse, StationType, UserRole } from "common";
import { useContext, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { ApiContext } from "../../components/context/ApiContext";
import { OrgContext } from "../../components/context/OrgContext";
import Header from "../../components/header/Header";
import Itembadge from "../../components/header/ItemBadge";
import MenuPopover from "../../components/header/MenuPopover";
import { getLocalSettings, setLocalSettings, setPriceAppConfig } from "../../utils/localStorage";
import OneClickPricing from "./OneClickPricing";
import RapidPricing from "./RapidPricing";

export default function PricerPage() {
    const { orgId, role, email } = useContext(OrgContext);
    const { processes: processApi, organizations: organizationsApi } = useContext(ApiContext);
    const [stationType, setStationType] = useState(getLocalSettings().stationType);
    const { lastMessage, sendJsonMessage, readyState } = useWebSocket("ws://localhost:8089");

    const [itemsCount, setItemsCount] = useState<number>();

    const getItemsCount = () => {
        processApi
            ?.itemsTodayList()
            .then((resp) => {
                setItemsCount(resp.data.itemsToday);
            })
            .catch((err: AxiosError) => {
                console.error((err.response?.data as ErrorResponse)?.error || "An error occurred. Please try again.");
            });
    };

    const getBody = () => {
        if (readyState === ReadyState.OPEN) {
            return (
                <OneClickPricing
                    lastMessage={lastMessage}
                    sendJsonMessage={sendJsonMessage}
                    getItemsCount={getItemsCount}
                />
            );
        }

        return <RapidPricing getItemsCount={getItemsCount} />;
    };

    useEffect(() => {
        organizationsApi?.priceAppConfigDetail(orgId).then((response) => {
            if (response.data.priceAppConfig) setPriceAppConfig(response.data.priceAppConfig);
        });
    }, []);

    return (
        <>
            <div className="flex h-dvh flex-col overflow-y-clip overscroll-none">
                <Header
                    darkMode
                    left={
                        <div className="flex flex-row items-center gap-4 pl-4">
                            <MenuPopover role={role as UserRole} darkMode />
                            {!isMobile && (
                                <div className="flex h-[20px] shrink-0 items-center justify-end">
                                    <div
                                        className="cursor-pointer rounded bg-thriftlyGreyDark px-2 py-1 text-xs uppercase text-thriftlyOffWhite"
                                        onClick={() => {
                                            const newStationType =
                                                stationType === StationType.StationTypeHardline
                                                    ? StationType.StationTypeSoftline
                                                    : StationType.StationTypeHardline;
                                            setStationType(newStationType);
                                            setLocalSettings({ ...getLocalSettings(), stationType: newStationType });
                                        }}
                                    >
                                        {stationType === StationType.StationTypeHardline ? "HARD GOODS" : "SOFT GOODS"}
                                    </div>
                                </div>
                            )}
                        </div>
                    }
                    right={
                        <div className="flex flex-row items-center justify-end gap-4 pr-4">
                            {!isMobile && <div className="text-thriftlyOffWhite">{email}</div>}
                            <Itembadge role={role as UserRole} itemsCount={itemsCount} />
                        </div>
                    }
                />
                <div className="flex h-full w-full flex-col items-center justify-center border-t-2 border-thriftlyGreyDark">
                    {getBody()}
                </div>
            </div>
        </>
    );
}
