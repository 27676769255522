import { forwardRef } from "react";

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
    className?: string;
    backgroundColor?: string;
    textColor?: string;
    disabled?: boolean;
};

export const StyledButton = forwardRef<HTMLButtonElement, Props>(
    ({ className, backgroundColor, textColor, disabled, ...props }, ref) => {
        const getTextColor = () => {
            if (disabled) return "#5B5F61";
            if (textColor) return textColor;
            if (backgroundColor) return "#E3E5E7";
            return "#0B1B14";
        };

        return (
            <button
                style={{
                    background: disabled ? "#EAECED" : backgroundColor,
                    color: getTextColor(),
                }}
                className={`body-b2-medium focus-visible:ring-button-secondary-hover focus-visible:bg-button-secondary inline-flex h-12 cursor-pointer items-center justify-center gap-2 whitespace-nowrap rounded-thriftlyButton border border-solid bg-thriftlyOffWhite px-4 font-semibold shadow-sm outline-none focus-visible:ring-1 focus-visible:ring-opacity-20 disabled:cursor-auto disabled:bg-thriftlyOffWhite disabled:text-thriftlyGreyLight ${className} ${disabled ? "cursor-not-allowed" : ""}`}
                ref={ref}
                {...props}
                type={props.type ?? "button"}
            >
                {props.children}
            </button>
        );
    }
);
StyledButton.displayName = "StyledButton";
