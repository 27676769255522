import { Container, ContainerResponse, NewZebraBrowserPrint, UserRole, ZebraPrinter } from "common";
import { useContext, useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { toast } from "react-toastify";
import { Button } from "../../components/common/Button";
import CustomInputText from "../../components/common/CustomInputText";
import { ApiContext } from "../../components/context/ApiContext";
import { OrgContext } from "../../components/context/OrgContext";
import Header from "../../components/header/Header";
import MenuPopover from "../../components/header/MenuPopover";
import { getLocalSettings } from "../../utils/localStorage";
import { canPrint } from "../../utils/printers";

export default function ContainersPage() {
    const { VITE_API_BASE_URL } = import.meta.env;
    const { role, email } = useContext(OrgContext);
    const { containers: containersApi } = useContext(ApiContext);

    const [containerName, setContainerName] = useState<string>();
    const [container, setContainer] = useState<Container>();
    const printerLoaded = useRef(false);
    const [selectedPrinter, setSelectedPrinter] = useState<ZebraPrinter>();

    useEffect(() => {
        if (!canPrint() || selectedPrinter !== undefined || printerLoaded.current) return;
        printerLoaded.current = true;

        NewZebraBrowserPrint(getLocalSettings().labelPrinterServer)
            .getDefaultPrinter()
            .then((printer) => setSelectedPrinter(printer))
            .catch((error) => toast(`Error getting default printer: ${error}`));
    }, []);

    const printLabel = (container: Container) => {
        if (!canPrint() || selectedPrinter == undefined) return;

        const url = `${VITE_API_BASE_URL}/containers/${container.id}/label?print=true`;
        selectedPrinter.printZPL(url).catch((error) => {
            toast(`Error printing label: ${error}`);
            console.error(`Error printing label: ${error}`);
        });
    };

    const createContainer = () => {
        // Call API to create container
        containersApi
            ?.containersCreate({ name: containerName })
            .then(({ data }: { data: ContainerResponse }) => {
                toast("Container created.");
                setContainer(data.container);
                printLabel(data.container!);
            })
            .catch(() => {
                toast("An error occurred. Please try again.");
            });
    };

    return (
        <div className="h-dvh overflow-y-clip overscroll-none">
            <Header
                darkMode
                left={<MenuPopover role={role as UserRole} darkMode />}
                right={!isMobile && <div className="flex justify-end pr-4 text-thriftlyOffWhite">{email}</div>}
            />
            <div className="flex w-full justify-center border-t-2 border-thriftlyGreyDark pt-2">
                <div className="flex w-96 flex-col items-center gap-2 px-4">
                    <div className="text-center text-2xl text-thriftlyOffWhite">Create container</div>
                    <CustomInputText
                        className="w-full"
                        disabled={container !== undefined}
                        label="Container name"
                        placeholder="Container name"
                        onChange={setContainerName}
                    />
                    {container ? (
                        <>
                            <Button className="w-full" onClick={() => printLabel(container)}>
                                Reprint Label
                            </Button>
                            <Button className="w-full" onClick={() => setContainer(undefined)}>
                                Create more
                            </Button>
                        </>
                    ) : (
                        <Button className="w-full" onClick={() => createContainer()}>
                            Create
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
}
