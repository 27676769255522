import { CameraIcon } from "@shopify/polaris-icons";
import { isMobile } from "react-device-detect";
import { MdClose } from "react-icons/md";
import Loader from "../../../components/common/Loader";

export default function ImageCarousel(props: {
    images: string[];
    onSelectImageClick: (image: string) => void;
    onDeleteImageClick: (image: string) => void;
    onAddPhotoClick: () => void;
    isUploadingImage: boolean;
}) {
    const { images, onSelectImageClick, onDeleteImageClick, onAddPhotoClick, isUploadingImage } = props;

    const getSizeClasses = () => {
        if (isMobile) {
            return "h-[110px] w-[110px]";
        }

        return "h-[140px] w-[140px]";
    };

    const getSrcWithSize = (src: string) => {
        return `${src}?width=300&height=300`;
    };

    return (
        <div className="flex flex-row gap-2">
            <div className="flex flex-row gap-2 overflow-x-auto">
                {images.map((image, idx) => (
                    <div
                        key={idx}
                        className={`relative ${getSizeClasses()} flex shrink-0 overflow-hidden rounded-thriftlyCamera bg-thriftlyGreyDark`}
                    >
                        <img
                            className={`absolute ${getSizeClasses()} cursor-pointer object-contain`}
                            src={getSrcWithSize(image)}
                            alt="comparable"
                            onClick={(e) => {
                                e.stopPropagation();
                                onSelectImageClick(image);
                            }}
                        />
                        <div
                            className="relative ml-auto flex h-[40px] w-[40px] cursor-pointer rounded-bl-thriftlyCamera bg-white bg-opacity-40"
                            onClick={() => onDeleteImageClick(image)}
                        >
                            <MdClose className="h-[40px] w-[40px]" fill="#000000" />
                        </div>
                    </div>
                ))}
            </div>
            <div
                className={`${getSizeClasses()} flex shrink-0 cursor-pointer flex-col items-center justify-center overflow-hidden rounded-thriftlyCamera border border-thriftlyGreyDark text-thriftlyGreyLight`}
                onClick={() => onAddPhotoClick()}
            >
                {isUploadingImage ? (
                    <Loader />
                ) : (
                    <>
                        <CameraIcon fill="#989FA4" className="h-[24px] w-[24px]" />
                        Add photo
                    </>
                )}
            </div>
        </div>
    );
}
