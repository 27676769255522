import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom/client";
import ReactGA from "react-ga4";
import { RouterProvider } from "react-router-dom";
import "./index.css";
import Router from "./Router";

// Google Analytics
const { VITE_GA_MEASUREMENT_ID } = import.meta.env;
if (VITE_GA_MEASUREMENT_ID) {
    ReactGA.initialize(VITE_GA_MEASUREMENT_ID);
}

// Sentry
const { VITE_ENVIRONMENT, VITE_SENTRY_DSN } = import.meta.env;
if (VITE_SENTRY_DSN) {
    Sentry.init({
        dsn: VITE_SENTRY_DSN,
        environment: VITE_ENVIRONMENT,
        integrations: [
            new Sentry.BrowserTracing({
                // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: ["localhost", /^https:\/\/api.thriftly\.com/],
            }),
            new Sentry.Replay({
                maskAllText: false,
                blockAllMedia: false,
            }),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

ReactDOM.createRoot(document.getElementById("root")!).render(
    <React.StrictMode>
        <RouterProvider router={Router} />
    </React.StrictMode>
);
