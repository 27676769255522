import { forwardRef } from "react";

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
    className?: string;
    backgroundColor?: string;
    textColor?: string;
    disabled?: boolean;
};

export const Button = forwardRef<HTMLButtonElement, Props>(
    ({ className, backgroundColor, textColor, disabled, ...props }, ref) => {
        const getTextColor = () => {
            if (disabled) {
                return "text-gray-500";
            }
            if (textColor) {
                return textColor;
            }
            return "text-black";
        };

        const getBackgroundColor = () => {
            if (disabled) {
                return "bg-thriftlyGreyLight";
            }
            if (backgroundColor) {
                return backgroundColor;
            }
            return "bg-white";
        };

        return (
            <button
                className={`body-b2-medium focus-visible:ring-button-secondary-hover focus-visible:bg-button-secondary inline-flex h-12 cursor-pointer items-center justify-center gap-2 whitespace-nowrap rounded-thriftlyButton border border-solid px-4 font-semibold shadow-sm outline-none focus-visible:ring-1 focus-visible:ring-opacity-20 disabled:cursor-auto disabled:bg-thriftlyOffWhite disabled:text-thriftlyGreyLight ${className} ${getTextColor()} ${getBackgroundColor()} ${disabled ? "cursor-not-allowed" : ""}`}
                ref={ref}
                {...props}
                type={props.type ?? "button"}
            >
                {props.children}
            </button>
        );
    }
);
Button.displayName = "Button";
