import { ReactNode, useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { ROUTES } from "../../constants";
import Loader from "../common/Loader";
import { OrgContext } from "../context/OrgContext";

export const ProtectedRoute = ({ children }: { children?: ReactNode }) => {
    const { authenticated } = useContext(OrgContext);

    if (authenticated === null) {
        return <Loader />;
    }
    if (!authenticated) {
        return <Navigate to={ROUTES.LOGIN} />;
    }
    return children ? children : <Outlet />;
};
