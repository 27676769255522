import { AxiosError } from "axios";
import { ContainerResponse, ErrorResponse, Process, ProcessResponse, UserRole } from "common";
import { useContext, useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { toast } from "react-toastify";
import BarcodeListener from "../../components/BarcodeListener";
import { Button } from "../../components/common/Button";
import { ApiContext } from "../../components/context/ApiContext";
import { OrgContext } from "../../components/context/OrgContext";
import Header from "../../components/header/Header";
import MenuPopover from "../../components/header/MenuPopover";
import ProcessTile from "./ProcessTile";

export default function PlacePage() {
    const { role, email } = useContext(OrgContext);
    const { processes: processApi, containers: containersApi } = useContext(ApiContext);
    const successBeep = new Audio("/beep-success.mp3");
    const errorBeep = new Audio("/beep-error.mp3");

    const [priceLabels, setPriceLabels] = useState<Process[]>([]);

    // TODO: It is unclear why this is needed - priceLabels isn't updating properly
    const processes = useRef<Process[]>([]);
    useEffect(() => {
        processes.current = priceLabels;
    }, [priceLabels]);

    const onPriceLabel = (value: string) => {
        // Check if process is already in list
        if (processes.current.find((process) => process.id === value)) {
            toast("Item already scanned. Please try again.");
            errorBeep.play();
            return;
        }

        // get process from API to validate
        processApi
            ?.processesDetail(value)
            .then(({ data }: { data: ProcessResponse }) => {
                if (!data.process) {
                    toast("Invalid item. Please try again.");
                    return;
                }

                toast("Item added");
                successBeep.play();
                setPriceLabels((prev) => [...prev, data.process!]);
            })
            .catch((err: AxiosError) => {
                console.error(`error getting process: ${(err.response?.data as ErrorResponse)?.error}`);
                toast("An error occurred. Please try again.");
                errorBeep.play();
            });
    };

    const onContainerLabel = (value: string) => {
        if (processes.current.length === 0) {
            toast("Scan price label(s) first.");
            errorBeep.play();
            return;
        }

        // get container from API to validate
        containersApi
            ?.containersDetail(value)
            .then(({ data }: { data: ContainerResponse }) => {
                if (!data.container) {
                    toast("Invalid item. Please try again.");
                    errorBeep.play();
                    return;
                }

                // associate processes to container
                containersApi
                    ?.placeCreate(data.container.id!, {
                        processIds: processes.current.map((p) => p.id!),
                    })
                    .then(() => {
                        toast(`Items associated to ${data.container?.name}`);
                        successBeep.play();
                        setPriceLabels([]);
                    })
                    .catch((err: AxiosError) => {
                        console.error(`error getting process: ${(err.response?.data as ErrorResponse)?.error}`);
                        toast("An error occurred. Please try again.");
                        errorBeep.play();
                    });
            })
            .catch((err: AxiosError) => {
                console.error(`error getting process: ${(err.response?.data as ErrorResponse)?.error}`);
                toast("An error occurred. Please try again.");
                errorBeep.play();
            });
    };

    return (
        <div className="h-dvh overflow-y-clip overscroll-none">
            <Header
                darkMode
                left={<MenuPopover role={role as UserRole} darkMode />}
                right={!isMobile && <div className="flex justify-end pr-4 text-thriftlyOffWhite">{email}</div>}
            />
            <BarcodeListener
                onPriceLabel={(scannerId, value) => onPriceLabel(value)}
                onContainerLabel={(scannerId, value) => onContainerLabel(value)}
            />
            <div className="flex flex-col items-center border-t-2 border-thriftlyGreyDark pt-2">
                <div className="flex w-96 flex-col gap-2">
                    <div className="flex min-h-24 flex-col gap-2">
                        <div className="text-white">1. Scan price label(s)</div>
                        {priceLabels.map((process: Process) => {
                            return (
                                <ProcessTile
                                    key={process.id}
                                    process={process}
                                    onClick={(process: Process) =>
                                        setPriceLabels(priceLabels.filter((p) => p.id !== process.id))
                                    }
                                />
                            );
                        })}
                    </div>
                    <div className="min-h-24">
                        <div className={priceLabels.length === 0 ? "text-thriftlyGrey" : "text-white"}>
                            2. Scan destination container label
                        </div>
                    </div>
                    {priceLabels.length > 0 && (
                        <Button className="mt-auto" onClick={() => setPriceLabels([])}>
                            Cancel and clear list
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
}
