import { Comparable } from "common";
import { MdOpenInNew } from "react-icons/md";

export default function ComparableTileMobile(props: {
    comparable: Comparable;
    onClick?: (comparable: Comparable) => void;
    onClickImage?: (comparable: Comparable) => void;
}) {
    const { comparable, onClick, onClickImage } = props;

    return (
        <div
            className="flex cursor-pointer flex-row gap-2 text-white"
            onClick={() => (onClick ? onClick(comparable) : null)}
        >
            <div className="flex shrink-0 items-center overflow-hidden rounded-thriftlyButton border border-thriftlyGrey bg-thriftlyGreyDark">
                <img
                    className="max-h-[68px] w-[68px] object-contain"
                    src={comparable.image}
                    alt="comparable"
                    onClick={(e) => {
                        e.stopPropagation();
                        if (onClickImage) onClickImage(comparable);
                    }}
                />
            </div>
            <div className="flex w-full flex-col">
                <div className="flex justify-between gap-4">
                    <div className="line-clamp-2 text-lg">{comparable.title}</div>
                    <div className="flex flex-row items-center gap-4">
                        <div className="line-clamp-1 text-sm lowercase">{comparable.source}</div>
                        <div
                            className="flex h-[25px] w-[25px] shrink-0 cursor-pointer flex-row items-center justify-center"
                            onClick={(e) => {
                                e.stopPropagation();
                                window.open(comparable.link, "_blank");
                            }}
                        >
                            <div className="flex h-[25px] w-[25px] items-center justify-center rounded bg-thriftlyOffWhite bg-opacity-10">
                                <MdOpenInNew fill="#ffffff" className="h-[15px] w-[15px]" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="line-clamp-1 text-sm opacity-65">
                    {comparable.price ? comparable?.currency + comparable.price.toFixed(2) : <>&nbsp;</>}
                </div>
            </div>
        </div>
    );
}
